<template>
  <v-card class="pa-5 w-100" align="center">
    <v-form v-model="valid" ref="form" @submit.prevent="submit">
      <!-- Error -->
      <v-row v-if="error" justify="center" class="mb-5 mt-5 red--text">
        {{ error }}
      </v-row>
      <!-- Controls -->
      <v-row justify="center" class="mb-5">
        <v-btn :type="'submit'" :loading="loading" color="primaryButton" class="white--text">
          Submit
        </v-btn>
      </v-row>
      <!-- Header -->
      <h2 class="text-center">{{ formData.idPerson ? 'Edit' : 'Create' }} User</h2>
      <!-- Basic Info -->
      <v-row no-gutters flex-wrap class="mt-5">
        <v-col no-gutters cols="6" class="pr-2">
          <v-text-field v-model="formData.idPerson" label="ID" disabled></v-text-field>
        </v-col>
        <v-col no-gutters cols="6" class="pl-2">
          <v-text-field v-model="formData.email" label="Email" :rules="emailRules" required :disabled="!userData.isAdmin || formData.idPerson != null"></v-text-field>
        </v-col>
        <v-col no-gutters cols="12">
          <v-text-field v-model="formData.personName" label="Display Name" :rules="requiredRules" :disabled="!userData.isAdmin"></v-text-field>
        </v-col>
        <v-col no-gutters cols="12">
          <v-switch v-model="formData.isAdmin" @change="toggleAdmin" label="Administrator" :disabled="loading || !userData.isAdmin || !formData.idPerson || formData.idPerson == userData.idUser" inset></v-switch>
        </v-col>
        <v-col no-gutters cols="12">
          <v-switch v-model="formData.isDarkMode" label="Dark Mode" inset></v-switch>
        </v-col>
      </v-row>
      <!-- Employers -->
      <h3 v-if="formData.employers && formData.employers.length > 0" class="mt-5 text-center">Employers</h3>
      <v-btn v-if="userData.isAdmin"
        @click="addEmployer"
        :loading="loading"
        class="mt-5 white--text"
        color="primaryButton"
      >
        Add Employer
      </v-btn>
      <div>
        <v-card v-for="(employer, i) in formData.employers" :key="i" class="mt-5 pa-5" color="card2">
          <v-row no-gutters flex-wrap>
            <v-col no-gutters cols="6" class="pr-2">
              <v-select v-if="userData.isAdmin"
                v-model="employer.idCompany"
                :items="companies"
                item-value="idCompany"
                item-text="name"
                label="Company"
                :rules="requiredRules"
              ></v-select>
              <v-text-field v-else v-model="employer.companyName" label="Company" disabled></v-text-field>
            </v-col>
            <v-col no-gutters cols="6" class="pl-2">
              <v-select v-if="userData.isAdmin"
                v-model="employer.position"
                :items="employeePositions"
                item-value="name"
                item-text="name"
                label="Position"
                :rules="requiredRules"
              ></v-select>
              <v-text-field v-else v-model="employer.position" label="Position" disabled></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn v-if="userData.isAdmin"
              @click="removeEmployer(employer)"
              :disabled="loading"
              class="ml-2"
              color="red"
            >
              <v-icon>mdi-trash-can</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </div>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserForm',

    props: ['inputData', 'isProfile'],

    computed: {
      ...mapGetters([
        'userData'
      ])
    },

    data () {
      return {
        companies: [],
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        employeePositions: [],
        error: '',
        formData: { },
        loading: false,
        requiredRules: [
          v => !!v || 'Field is required.'
        ],
        valid: false
      }
    },

    created () {
      this.formData = this.inputData
      if (this.userData.isAdmin && this.companies.length == 0 && this.formData.employers) {
        this.getCompanies()
        this.getEmployeePositions()
      }
    },
    
    watch: {
      inputData () {
        this.formData = this.inputData
        if (this.userData.isAdmin && this.companies.length == 0 && this.formData.employers) {
          this.getCompanies()
          this.getEmployeePositions()
        }
      }
    },

    methods: {
      addEmployer () {
        if (this.companies.length == 0) {
          this.getCompanies()
          this.getEmployeePositions()
        }
        if (!this.formData.employers) {
          this.formData.employers = []
        }
        this.formData.employers.push({ })
        this.$forceUpdate()
      },

      getCompanies () {
        this.loading = true
        this.$store.dispatch('getCompanies')
        .then((resp) => {
          this.companies = resp.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
          this.$forceUpdate()
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      getEmployeePositions () {
        this.loading = true
        this.$store.dispatch('getCompanyEmployeePositions')
        .then((resp) => {
          this.employeePositions = resp
          this.$forceUpdate()
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      removeEmployer (employer) {
        const index = this.formData.employers.indexOf(employer)
        if (index > -1) {
          this.formData.employers.splice(index, 1)
          this.$forceUpdate()
        }
      },

      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          // Employers
          if (this.formData.employers) {
            for (var i = 0; i < this.formData.employers.length; i++) {
              this.formData.employers[i] = {
                idCompany: this.formData.employers[i].idCompany,
                idPosition: this.employeePositions.find(position => position.name == this.formData.employers[i].position).idCompanyEmployeePosition
              }
            }
          }

          var dispatchType
          if (this.isProfile) dispatchType = "updateUserProfile"
          else dispatchType = this.formData.idPerson ? "updateUser" : "createUser"
          this.$store.dispatch(dispatchType, this.formData)
          .then((userData) => {
            this.$emit('onSubmitComplete', userData)
            this.loading = false
          })
          .catch((error) => {
            this.error = error
            this.loading = false
          })
        }
      },

      toggleAdmin () {
        this.loading = true
        const dispatchType = this.formData.isAdmin ? 'createAdmin' : 'deleteAdmin'
        this.$store.dispatch(dispatchType, this.formData.idPerson)
        .then(() => {
          this.loading = false
        })
        .catch((error) => {
          this.error = error
          this.loading = false
        })
      },

      validateForm() {
        this.$refs.form.validate()
      }
    }
  }
</script>
